export const categoryConstansts = {
    GET_ALL_CATEGORIES_REQUEST: "GET_ALL_CATEGORIES_REQUEST",
    GET_ALL_CATEGORIES_SUCCESS: "GET_ALL_CATEGORIES_SUCCESS",
    GET_ALL_CATEGORIES_FAILURE: "GET_ALL_CATEGORIES_FAILURE",
    ADD_NEW_CATEGORY_REQUEST: "ADD_NEW_CATEGORY_REQUEST",
    ADD_NEW_CATEGORY_SUCCESS: "ADD_NEW_CATEGORY_SUCCESS",
    ADD_NEW_CATEGORY_FAILURE: "ADD_NEW_CATEGORY_FAILURE",
};

export const productConstants = {
    GET_PRODUCTS_BY_SLUG_REQUEST: "GET_PRODUCTS_BY_SLUG_REQUEST",
    GET_PRODUCTS_BY_SLUG_SUCCESS: "GET_PRODUCTS_BY_SLUG_SUCCESS",
    GET_PRODUCTS_BY_SLUG_FAILURE: "GET_PRODUCTS_BY_SLUG_FAILURE",
    GET_PRODUCT_PAGE_REQUEST: "GET_PRODUCT_PAGE_REQUEST",
    GET_PRODUCT_PAGE_SUCCESS: "GET_PRODUCT_PAGE_SUCCESS",
    GET_PRODUCT_PAGE_FAILURE: "GET_PRODUCT_PAGE_FAILURE",

    GET_PRODUCT_SEARCH_REQUEST: "GET_PRODUCT_SEARCH_REQUEST",
    GET_PRODUCT_SEARCH_SUCCESS: "GET_PRODUCT_SEARCH_SUCCESS",
    GET_PRODUCT_SEARCH_FAILURE: "GET_PRODUCT_SEARCH_FAILURE",

    GET_PRODUCT_FILTER_SUCCESS: "GET_PRODUCT_FILTER_SUCCESS",
    GET_PRODUCT_FILTER_RANGE_SUCCESS: "GET_PRODUCT_FILTER_RANGE_SUCCESS",


    GET_PRODUCT_DETAILS_BY_ID_REQUEST: "GET_PRODUCT_DETAILS_BY_ID_REQUEST",
    GET_PRODUCT_DETAILS_BY_ID_SUCCESS: "GET_PRODUCT_DETAILS_BY_ID_SUCCESS",
    GET_PRODUCT_DETAILS_BY_ID_FAILURE: "GET_PRODUCT_DETAILS_BY_ID_FAILURE",
};

export const authConstants = {
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_FAILURE: "LOGIN_FAILURE",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",

    GOOGLE_LOGIN_REQUEST: "GOOGLE_LOGIN_REQUEST",
    GOOGLE_LOGIN_FAILURE: "GOOGLE_LOGIN_FAILURE",
    GOOGLE_LOGIN_SUCCESS: "GOOGLE_LOGIN_SUCCESS",

    FACEBOOK_LOGIN_REQUEST: "FACEBOOK_LOGIN_REQUEST",
    FACEBOOK_LOGIN_FAILURE: "FACEBOOK_LOGIN_FAILURE",
    FACEBOOK_LOGIN_SUCCESS: "FACEBOOK_LOGIN_SUCCESS",

    LOGOUT_REQUEST: "LOGOUT_REQUEST",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    LOGOUT_FAILURE: "LOGOUT_FAILURE",

    SIGNUP_REQUEST: "SIGNUP_REQUEST",
    SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
    SIGNUP_FAILURE: "SIGNUP_FAILURE",
};

export const cartConstants = {
    ADD_TO_CART_REQUEST: "ADD_TO_CART_REQUEST",
    ADD_TO_CART_SUCCESS: "ADD_TO_CART_SUCCESS",
    ADD_TO_CART_FAILURE: "ADD_TO_CART_FAILURE",
    RESET_CART: "RESET_CART",

    REMOVE_CART_ITEM_REQUEST: "REMOVE_CART_ITEM_REQUEST",
    REMOVE_CART_ITEM_SUCCESS: "REMOVE_CART_ITEM_SUCCESS",
    REMOVE_CART_ITEM_FAILURE: "REMOVE_CART_ITEM_FAILURE",

    SAVE_CART_ITEM_REQUEST: 'SAVE_CART_ITEM_REQUEST',
    SAVE_CART_ITEM_SUCCESS: 'SAVE_CART_ITEM_SUCCESS',
    SAVE_CART_ITEM_FAILURE: 'SAVE_CART_ITEM_FAILURE',

};

export const userConstants = {
    GET_USER_ADDRESS_REQUEST: "GET_USER_ADDRESS_REQUEST",
    GET_USER_ADDRESS_SUCCESS: "GET_USER_ADDRESS_SUCCESS",
    GET_USER_ADDRESS_FAILURE: "GET_USER_ADDRESS_FAILURE",
    ADD_USER_ADDRESS_REQUEST: "ADD_USER_ADDRESS_REQUEST",
    ADD_USER_ADDRESS_SUCCESS: "ADD_USER_ADDRESS_SUCCESS",
    ADD_USER_ADDRESS_FAILURE: "ADD_USER_ADDRESS_FAILURE",
    ADD_USER_ORDER_REQUEST: "ADD_USER_ORDER_REQUEST",
    ADD_USER_ORDER_SUCCESS: "ADD_USER_ORDER_SUCCESS",
    ADD_USER_ORDER_FAILURE: "ADD_USER_ORDER_FAILURE",
    RESET_ADDRESS: 'RESET_ADDRESS',

    GET_USER_ORDER_REQUEST: "GET_USER_ORDER_REQUEST",
    GET_USER_ORDER_SUCCESS: "GET_USER_ORDER_SUCCESS",
    GET_USER_ORDER_FAILURE: "GET_USER_ORDER_FAILURE",

    GET_USER_ORDER_DETAILS_REQUEST: "GET_USER_ORDER_DETAILS_REQUEST",
    GET_USER_ORDER_DETAILS_SUCCESS: "GET_USER_ORDER_DETAILS_SUCCESS",
    GET_USER_ORDER_DETAILS_FAILURE: "GET_USER_ORDER_DETAILS_FAILURE",
};